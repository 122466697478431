html {
  &.scroll-locked {
    height: 100%;
    overflow: hidden;

    body {
      min-height: 100%;
      height: 100%;
      overflow-y: hidden;
    }

    #root,
    #skilltest-app {
      width: 100%;
      height: 100%;
      max-height: 100vh;
    }
  }
}

body {
  padding: 0;
  margin: 0;
}

body,
input,
textarea {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

p {
  line-height: 1.5;
  margin-block-start: 0em;
}

table.content-table {
  width: 100%;
  border-collapse: collapse;
}

table.content-table th,
table.content-table td {
  box-sizing: border-box;
  position: relative;
  text-align: left;
  vertical-align: top;
  padding: 10px 15px;
}

table.content-table th {
  font-weight: 600;
  color: rgb(94, 108, 132);
  border-bottom: 2px solid rgb(223, 225, 230);
}

table.content-table td {
  border-bottom: 1px solid rgb(223, 225, 230);
  vertical-align: middle;
}

form.content-form {
  max-width: 800px;
}

.admin-content-wrapper {
  max-width: 800px;
}

.content-form .content-form__item {
  margin-top: 8px;
}

.content-form label.content-form__label {
  font-size: 0.8571428571428571em;
  font-style: inherit;
  line-height: 1.3333333333333333;
  color: #6b778c;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 4px;
  display: block;
}

.content-form__error {
  color: #ca5252;
  margin-top: 5px;
  font-size: 14px;
  font-weight: 500;
}

.content-form__radio-item {
  margin-top: 10px;
}

.content-form__radio-item label {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.content-form__radio-item input[type='radio'] {
  margin-right: 5px;
}

.form-input {
  background-color: #fafbfc;
  border-color: #dfe1e6;
  border-radius: 3px;
  border-width: 2px;
  border-style: solid;
  box-sizing: border-box;
  color: #091e42;
  font-size: 14px;
  padding: 8px 6px;
  width: 100%;
  outline: none;

  transition: border-color 0.15s;
}

.form-input:focus {
  border-color: #4d9aff;
}

:root {
  --brand-color: #143ccc;
}

.ReactModal__Overlay {
  z-index: 15;
}

ol {
  li {
    margin-bottom: 15px;
  }
}

ul.skills__list {
  li {
    margin-bottom: 15px;
  }
}

.drag__handle {
  position: relative;
  top: 1px;
  display: block;
  width: 18px;
  height: 11px;
  opacity: 0.25;
  margin-right: 20px;
  cursor: row-resize;
  background: -webkit-linear-gradient(
    top,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
  background: linear-gradient(
    180deg,
    #000,
    #000 20%,
    #fff 0,
    #fff 40%,
    #000 0,
    #000 60%,
    #fff 0,
    #fff 80%,
    #000 0,
    #000
  );
}

.skilltest__intro-page {
  width: 700px;
  margin: 15px auto 0 auto;
  padding: 15px;
}

.skills-welcome__paragraph {
  font-size: 18px;
  margin-top: 15px;
}
